
export default {
    '登录\n华美云':[
        // {
        //     title:'提供定制数据服务的平台！为每一个行业对象定制数据主页！',
        //     reverse:true,
        //     images: [
        //         'https://hm-chuyu.oss-cn-shenzhen.aliyuncs.com/%E5%AE%98%E7%BD%91%E5%9B%BE%E7%89%87/2024/%E7%99%BB%E5%BD%95%E5%8D%8E%E7%BE%8E%E4%BA%91/%E6%88%AA%E5%B1%8F2024-02-23%2011.27.18.png'
        //     ],
        //     content:[
                
        //         ``,
        //     ]
        // },
        {
            title:'华美云业主主页：酒店资产全生命周期数据服务',
            reverse:true,
            images: [
                'https://hm-chuyu.oss-cn-shenzhen.aliyuncs.com/%E5%AE%98%E7%BD%91%E5%9B%BE%E7%89%87/2024/%E7%99%BB%E5%BD%95%E5%8D%8E%E7%BE%8E%E4%BA%91/%E4%B8%9A%E4%B8%BB%E4%B8%BB%E9%A1%B5.png'
            ],
            imageStyle:"border: 0.5px solid #888; border-radius: 2px;",
            content:[
                `投资期 - 快速了解当地城市宏观、酒店市场概况`,
                `筹建期 - 甄选品牌、设计公司、供应商，减少时间和投资浪费`,
                `经营期 - 跟踪监测酒店经营水平，提供营销、经营提升案例`,
                `退出期 - 金蝉脱壳，实现资产管理的良性循环`,
            ],
            links:[
                {
                    text:"点击登录华美云",
                    url:"https://c.huamei2001.com"
                }
            ]
        },
        {
            title:'华美云酒管主页：赋能拓展，升维市场连接',
            images: [
                'https://hm-chuyu.oss-cn-shenzhen.aliyuncs.com/%E5%AE%98%E7%BD%91%E5%9B%BE%E7%89%87/2024/%E7%99%BB%E5%BD%95%E5%8D%8E%E7%BE%8E%E4%BA%91/%E9%85%92%E7%AE%A1%E4%B8%BB%E9%A1%B51.png',
                'https://hm-chuyu.oss-cn-shenzhen.aliyuncs.com/%E5%AE%98%E7%BD%91%E5%9B%BE%E7%89%87/2024/%E7%99%BB%E5%BD%95%E5%8D%8E%E7%BE%8E%E4%BA%91/%E9%85%92%E7%AE%A1%E4%B8%BB%E9%A1%B52.png',
                'https://hm-chuyu.oss-cn-shenzhen.aliyuncs.com/%E5%AE%98%E7%BD%91%E5%9B%BE%E7%89%87/2024/%E7%99%BB%E5%BD%95%E5%8D%8E%E7%BE%8E%E4%BA%91/%E9%85%92%E7%AE%A1%E4%B8%BB%E9%A1%B53.png',
            ],
            imageStyle:"border: 0.5px solid #888; border-radius: 2px;",
            content:[
                `聚焦存量和增量市场，了解你的对手在做什么`,
                `拿到项目信息，快速对当地酒店市场研判`,
                `制作品牌推广文件`,
            ],
            links:[
                {
                    text:"点击登录华美云",
                    url:"https://c.huamei2001.com"
                }
            ]
        },
        {
            title:'华美云酒店主页：赋能营销、经营，降本增效',
            reverse:true,
            imageStyle:"border: 0.5px solid #555; border-radius: 2px;",
            images: [
                'https://hm-chuyu.oss-cn-shenzhen.aliyuncs.com/%E5%AE%98%E7%BD%91%E5%9B%BE%E7%89%87/2024/%E7%99%BB%E5%BD%95%E5%8D%8E%E7%BE%8E%E4%BA%91/%E9%85%92%E5%BA%97%E4%B8%BB%E9%A1%B51.png',
                'https://hm-chuyu.oss-cn-shenzhen.aliyuncs.com/%E5%AE%98%E7%BD%91%E5%9B%BE%E7%89%87/2024/%E7%99%BB%E5%BD%95%E5%8D%8E%E7%BE%8E%E4%BA%91/%E9%85%92%E5%BA%97%E4%B8%BB%E9%A1%B53.png',
                'https://hm-chuyu.oss-cn-shenzhen.aliyuncs.com/%E5%AE%98%E7%BD%91%E5%9B%BE%E7%89%87/2024/%E7%99%BB%E5%BD%95%E5%8D%8E%E7%BE%8E%E4%BA%91/%E9%85%92%E5%BA%97%E4%B8%BB%E9%A1%B52.png',
            ],
            content:[
                `聚焦存量和增量市场，了解你的对手在做什么`,
                `拿到项目信息，快速对当地酒店市场研判`,
                `制作品牌推广文件`,
            ],
            links:[
                {
                    text:"点击登录华美云",
                    url:"https://c.huamei2001.com"
                }
            ]
        },
        {
            title:'酒店智能化AI平台：酒店管理尽管问',
            images: [
                'https://hm-chuyu.oss-cn-shenzhen.aliyuncs.com/%E5%AE%98%E7%BD%91%E5%9B%BE%E7%89%87/2024/%E7%99%BB%E5%BD%95%E5%8D%8E%E7%BE%8E%E4%BA%91/%E9%85%92%E5%BA%97%E4%B8%BB%E9%A1%B52.png',
                // 'https://hm-chuyu.oss-cn-shenzhen.aliyuncs.com/%E5%AE%98%E7%BD%91%E5%9B%BE%E7%89%87/2024/%E7%99%BB%E5%BD%95%E5%8D%8E%E7%BE%8E%E4%BA%91/%E5%B0%BD%E7%AE%A1%E9%97%AE3.png',
                'https://hm-chuyu.oss-cn-shenzhen.aliyuncs.com/%E5%AE%98%E7%BD%91%E5%9B%BE%E7%89%87/2024/%E7%99%BB%E5%BD%95%E5%8D%8E%E7%BE%8E%E4%BA%91/%E5%B0%BD%E7%AE%A1%E9%97%AE2.png',
            ],
            imageStyle:"border: 0.5px solid #888; border-radius: 2px;",
            content:[
                `酒店管理者：提升运营水平`,
                `酒店一线员工：提升服务质量`,
                `酒店业主：提升监管水平`,
            ],
            links:[
                {
                    text:"点击进入尽管问",
                    url:"https://hotelmgmt.jinguanwen.com"
                }
            ]
        },
    ],
    '华美\n简介':[
        {
            // title:'华美简介',
            summary:'',
            reverse:true,
            images: [
                'https://hm-chuyu.oss-cn-shenzhen.aliyuncs.com/%E5%AE%98%E7%BD%91%E5%9B%BE%E7%89%87/2024/%E5%B0%8F%E5%9B%BE/%E5%8D%8E%E7%BE%8E%E7%AE%80%E4%BB%8B/01.jpg'
                // 'https://oss.huamei2001.com/hm2022/官网图片/IMG_3392.JPG',
                // 'https://oss.huamei2001.com/temp/%E5%8D%8E%E7%BE%8E%E5%8F%91%E5%B1%95.jpg'
            ],
            children:[
                {
                    title:'华美愿景',
                    content:[
                        '聚焦痛点问题，充分利用大数据、人工智能等先进技术，为客户提供更高价值且更易实施的专业咨询服务。',
                    ]
                },
                {
                    title:'华美价值观',
                    content:[
                        '勇敢创新，快乐奋斗',
                    ]
                },
                {
                    title:'华美发展',
                    content:[
                        '华美顾问集团于2001年在中国深圳创立，致力于为酒店、酒店集团、酒店地产、旅游度假目的地、特色小镇、幸福产业等项目提供全面咨询顾问服务，目前在深圳、北京、上海、成都设有4个办公室，已在中国400+城市为1700+项目提供高质量咨询服务。',
                        // '深圳市华美顾问有限公司曾获得改革开放30年影响中国酒店100人（王炜文）、希尔顿酒店集团中国区业主协会特聘顾问（王卉阳）、中国饭店业品牌价值峰会最具价值顾问咨询机构、世界酒店联盟大会最佳顾问咨询机构等荣誉。',
                    ]
                }
            ]
        },
        {
            title:'打碎铁笼，创造新华美！',
            portrait:true,
            images: [
                'https://hm-chuyu.oss-cn-shenzhen.aliyuncs.com/%E5%AE%98%E7%BD%91%E5%9B%BE%E7%89%87/2024/%E5%B0%8F%E5%9B%BE/%E5%8D%8E%E7%BE%8E%E7%AE%80%E4%BB%8B/02.jpg'
                // 'https://oss.huamei2001.com/hm2022/官网图片/IMG_0905.JPG',
            ],
            content:[
                `华美顾问集团 执行董事 王炜文`,
                `2023年，有的时间感觉过得很慢，有的时间又感觉过得飞快。`,
                `与2022年相比，2023年的世界更加混乱危险，2023年的中国更加内忧外患。美国在凶狠地折腾中国，欧洲在愚蠢地脱钩中国，小国菲律宾也壮着胆子开始挑衅中国。我们的中央撤换了几位部长和几位上将，我们的有关部门在让房地产硬着陆、消灭外培行业、重罚阿里巴巴之后，再次用一纸公文让腾讯一天就损失了1千多亿市值。凶狠的政策不仅让腾讯损失惨重，也让民营企业家和科技股投资者们的信心再次冰冷刺骨。`,
                `与国家的状态相比，华美的2023要好得多。虽然仍有种种挣扎和艰难，但是在一片不确定性的灰蒙中，我们已经实实在在地看到了“打碎铁笼，创造新华美”的曙光。对增量市场、存量市场、利基市场、数据市场和赋能市场的未来发展趋势和潜在机会，对华美如何打造稳定的基础收入和先进的组织架构，对怎样迎接AI智能时代的到来，我们已经建立了前所未有的清晰认知和创新行动。“铁笼”终于开始出现裂纹了，不仅有突破，而且是升维，这让我们感到无比兴奋。2024，我们大有可为！`,
                `听罗胖讲故事，一位做“戈壁旅行团”的创业者，安排孩子拉着蒙上眼睛的父母在戈壁上走完徒步旅程的最后一段路。这是多好的一个创意啊！到终点当孩子帮父母拿下眼罩时，怎么会没有一个热泪盈眶的深情拥抱。`,
                `2024，我们继续一起前行，共同成长。`,
            ]
        },
    ],
        
    'ESG':[
        {
            title:'每年发布《酒店ESG在行动》年度报告',
            summary:'',
            images: [
                'https://hm-chuyu.oss-cn-shenzhen.aliyuncs.com/%E5%AE%98%E7%BD%91%E5%9B%BE%E7%89%87/2024/%E5%B0%8F%E5%9B%BE/ESG/01.jpg'
                // 'https://oss.huamei2001.com/hm2022-1/关于华美/%E5%8F%AF%E6%8C%81%E7%BB%AD%E5%8F%91%E5%B1%95%20(1).jpg?versionId=CAEQLhiBgIDkiOGn9RciIDE5MmE5MTdlNmRmNzRiMDQ5MjZiOWNmYjYzZTI5ODRl'
            ],
            children:[
                {
                    title:'',
                    content:[
                        ``,
                        // `为能源集团策划“低碳主题”酒店品牌、为林业集团策划“保持生物多样性”酒店设计概念`,
                    ],
                }
            ],
            links:[
                {
                    text:"点击阅读《2023酒店ESG在行动》",
                    url:"https://huamei2001.com/article?id=3425"
                }
            ]
        },
        {
            title:'在全国多个城市开展客房浴缸使用率调查活动，大力倡导《减少浴缸》',
            reverse:true,
            images: [
                'https://hm-chuyu.oss-cn-shenzhen.aliyuncs.com/%E5%AE%98%E7%BD%91%E5%9B%BE%E7%89%87/2024/%E5%87%8F%E5%B0%91%E6%B5%B4%E7%BC%B8%E5%B0%8F%E5%9B%BE.jpg'
            ],
            content:[
                
                ``,
            ],
            links:[
                {
                    text:"点击阅读《减少浴缸调查报告》",
                    url:"https://mp.weixin.qq.com/s?__biz=MjM5NzE2NzA5Nw==&mid=2649772402&idx=1&sn=fd28bc2c2cbf2f05f6c870fb4a0c6afd&chksm=bedadbe489ad52f248dc80037d74cdbff020fbce84316770fc0f9e879fff4678cddbf7e388de#rd"
                }
            ]
        },
        {
            title:'为服务项目策划原创可持续发展主题艺术装置',
            video:'https://hm-chuyu.oss-cn-shenzhen.aliyuncs.com/%E5%AE%98%E7%BD%91%E5%9B%BE%E7%89%87/2024/%E8%A7%86%E9%A2%91/%E6%97%A0%E5%A4%84%E5%AE%B9%E8%BA%AB.mp4',
            controls:true,
            autoplay:true,
            content:[
                `年轮线（记录雪山、湖泊、草原等轮廓线的变化）`,
                `无处容身（记录办公室每月产生垃圾体积）`,
            ]
        },
        {
            title:'碳足迹管理',
            reverse:true,
            images: [
                'https://hm-chuyu.oss-cn-shenzhen.aliyuncs.com/%E5%AE%98%E7%BD%91%E5%9B%BE%E7%89%87/2024/%E5%B0%8F%E5%9B%BE/ESG/02.jpg'
                // 'https://oss.huamei2001.com/hm2022/官网图片/%E5%8F%AF%E6%8C%81%E7%BB%AD%E5%8F%91%E5%B1%951.jpg?versionId=CAEQLhiBgMCMzbGa9RciIDU0ZmZkMmExNjA0OTRkMmY5NjVjNTJlZDEwOGQzZmYy'
            ],
            content:[
                
                `为客户提供“选择在线会议替代现场会议即可扣减对应咨询费”的选择条款。`,
                `所有印刷品均采用100%再生纸。`,
            ]
        },
        {
            title:'扶贫礼物',
            images: [
                'https://hm-chuyu.oss-cn-shenzhen.aliyuncs.com/%E5%AE%98%E7%BD%91%E5%9B%BE%E7%89%87/2024/%E5%B0%8F%E5%9B%BE/ESG/03.jpg'
                // 'https://oss.huamei2001.com/temp/%E6%89%B6%E8%B4%AB.jpg?'
            ],
            content:[
                `与扶贫机构合作，用“扶贫礼物”感谢合作伙伴及发放员工福利。`,
            ],
            action:{
                text:'有意合作的公益组织请发邮件至 ',
                email:"nihao@huamei2001.com"
            }
        },
        {
            title:'每年提供30个实习生职位',
            reverse:true,
            images: [
                'https://oss.huamei2001.com/hm2022/官网图片/%E8%8A%B1%E5%9B%BE.jpg?versionId=CAEQLhiBgICS8K.U9RciIGNlYjBjZThkMmU5ZDRkNGU4N2ZlNGMwODc4YTQ0MDY5',
                // 'https://oss.huamei2001.com/hm2022/banner-summer-internship-programme.jpg',
                'https://oss.huamei2001.com/temp/%E5%AE%9E%E4%B9%A0%E7%94%9F.jpg',
            ],
            content:[
                `华美顾问每年为全球各大酒店/旅游管理学院研究生和本科生提供超过30个实习职位，合作院校包括康奈尔、英国萨里、瑞士洛桑、香港理工、澳门科大、人民大学、北京二外、暨南大学、南开大学等。`,
            ],
            action:{
                text:'有意合作的院校请发邮件至 ',
                email:"nihao@huamei2001.com"
            }
        },
    ],
    '社会\n责任':[
        // {
        //     title:'首席知识官-赵焕焱',
        //     images: [
        //         'https://oss.huamei2001.com/temp/%E8%B5%B5%E8%80%81%E5%B8%88.jpg',
        //     ],
        //     content:[
        //         `赵焕焱，⾼级经济师，中国酒店⾏业著名观察家和分析师，2007年起担
        //         任华美顾问集团⾸席知识官。
        //         从2004年开始，赵焕焱先⽣⻓期坚持搜集、整理、分析中国酒店⾏业发
        //         展的⼤事件和⼤数据。截⾄2020年底，累计 参加各类⾏业论坛并发表演
        //         讲216次，在各⼤新闻媒体上发表⽂章795篇，接受报刊、电台、电视台
        //         等媒体采访 2742次。
        //         赵焕焱先⽣曾获得中国酒店⾏业改⾰开放30年纪念⼤会“影响中国酒店
        //         100⼈”、中国酒店及商⽤厨具⾏业⼤会“终身 成就奖”、CHTA全球年会
        //         “特别突出贡献奖”、“中国酒店卓越贡献⾦⻰奖”、中瑞酒店管理学院“数
        //         据价值智囊奖”、 GBE论坛“杰出贡献奖”等荣誉。`,
        //     ],
        //     qrImages:[
        //         {
        //             title:'赵老师微信公众号',
        //             url:'/images/zhaohuanyanweixin.jpeg',
        //         },
        //         {
        //             title:'赵老师微博',
        //             url:'/images/zhaohuanyanweibo.jpeg'
        //         },
        //     ]
        // },
    ],
    '员工\n与文化':[
        {
            // title:'员工与文化',
            images: [
                'https://oss.huamei2001.com/temp/%E5%91%98%E5%B7%A5%E6%96%87%E5%8C%96.JPG',
                'https://oss.huamei2001.com/temp/%E5%91%98%E5%B7%A5%E6%96%87%E5%8C%96.JPG',
                'https://oss.huamei2001.com/temp/%E9%A3%8E%E8%BD%A6.jpg',
            ],
            video: 'https://oss.huamei2001.com/hm2022/官网图片/%E5%B7%A5%E4%BD%9C%E7%8E%AF%E5%A2%83.mp4',
            controls:false,
            autoplay:true,
            loop:false,
            summary:'',
            reverse:true,
            muted:true,
            children:[
                {
                    title:'工作环境及办公室文化',
                    images: [
                        'https://oss.huamei2001.com/temp/%E9%A3%8E%E8%BD%A6.jpg',
                    ],
                    content:[
                        '与重视客户对华美服务的体验一样，华美公司非常重视不断改善员工的工作环境和工作体验。华美目前在深圳、北京、上海、成都的四个办公室都位于城市中心繁华地段，写字楼的档次和知名度也属一流。',
                        '为了让顾问们更自由和舒适地工作，公司特别在每个办公室内布置了很多休闲空间。厌烦固定隔档的时候，可以在办公室找一个舒适的角落，泡一杯热茶，打开电脑开始一天的工作。办公室还配置了拉伸器材，定时播放音乐，提醒员工不要久坐不动。在寒冷的冬日，走进办公室还能喝到热气腾腾的红枣汤。',
                    ]
                }
            ]
        },
        {
            title:'员工培训',
            images: [
                'https://oss.huamei2001.com/hm2022-1/关于华美/%E5%91%98%E5%B7%A5%E5%9F%B9%E8%AE%AD.jpg?versionId=CAEQLhiBgIDS9OSn9RciIDM4ZTJiNjAwMjRmODRmODdiNmYyZTdlOWY3NTQxNTJh',
            ],
            content:[
                `图钉工作台提供丰富的行业数据、专业知识、工作流程和样板文件`,
                `员工群有机皮替（ChatGPT）、尽管问等多种机器人随时回答问题`,
                `人工智能生成系列培训视频`,
                `全员开展《研究驱动》活动`,
                `每月读书分享`,
                `每季度召开员工大会`,
            ]
        },
        {
            title:'员工福利',
            reverse:true,
            images: [
                'https://oss.huamei2001.com/hm2022-1/关于华美/%E5%91%98%E5%B7%A5%E7%A6%8F%E5%88%A9.jpg.JPEG?versionId=CAEQLhiBgMCDouen9RciIGM2ZGNhNDRmZjA4ZjQ4MjA4Mzc0ZTY4OTgyYWE3NjVm',
                'https://oss.huamei2001.com/hm2022/getty_168325476_330241.jpg',
            ],
            content:[
                '每年一次高规格体检。',
                '除政府规定的全部社会保险外，增加购买意外伤害险等商业保险。',
                '按国家规定享受全部法定假期和带薪休假。',
                '出差享受高标准的食宿条件及补贴。',
                '节日赠送礼物感恩员工的父母和家人（已执行16年）',
            ]
        },
        {
            title:'员工活动',
            video:'https://huamei2001.oss-cn-shenzhen.aliyuncs.com/hm2001/about/%E6%89%8B%E5%B7%A5%E5%A4%A7%E8%B5%9B.mp4',
            controls:true,
            autoplay:false,
            loop:false,
            muted:false,
            images: [
                'https://oss.huamei2001.com/temp/%E5%91%98%E5%B7%A5%E6%B4%BB%E5%8A%A8.jpg',
            ],
            content:[
                '公司倡导员工追求健康丰盛的生活方式，鼓励员工积极参与身体锻炼和健康的娱乐活动。',
                '3月健身减重“斋月”活动',
                '每年组织表现突出的员工外出旅游一次。',
                '每月各单位组织员工开展一次集体活动，包括体育锻炼、读书分享、节日聚会、春游或秋游等活动。',
            ]
        },
    ],
    '加入\n华美':[
        {
            title:'加入华美',
            reverse:true,
            videos:[
                'https://hm-chuyu.oss-cn-shenzhen.aliyuncs.com/%E5%AE%98%E7%BD%91%E5%9B%BE%E7%89%87/2024/%E8%A7%86%E9%A2%91/%E6%98%A5%E6%8B%9B%E8%A7%86%E9%A2%91.mp4'
            ],
            summary:'在华美，您将和许多才华出众、富于创意、充满激情的同事一起工作，我们彼此信任，相互尊重，勤奋且快乐地工作，努力实现公司与员工的共同成长。',
            children:[
            ]
        },
        {
            title:'当前招聘职位',
            images: [
                // 'https://oss.huamei2001.com/temp/decor/%E5%8A%A0%E5%85%A5-%E5%92%A8%E8%AF%A2%E9%A1%BE%E9%97%AE.jpg'
            ],
            children:[
                {
                    title:'咨询顾问（深圳/北京/上海/成都）',
                    content:[
                        '分析客户的需求，提供解决方案并且负责项目的执行。具有极强的分析、决策和解决问题的能力、定性和定量分析技术和知识。拥有卓越的团队合作精神和人际沟通技巧。知名大学酒店管理、旅游管理、MBA、财经、金融、建筑设计、室内设计、机电等专业本科或硕士毕业。国内985大学和全球酒店专业排名前10的大学毕业生、有咨询行业经验和酒店工作经验者优先。',
                    ]
                },
                {
                    title:'新媒体运营专员（深圳)',
                    reverse:true,
                    images: [
                        // 'https://oss.huamei2001.com/temp/decor/%E5%8A%A0%E5%85%A5-%E6%96%B0%E5%AA%92%E4%BD%93%E8%BF%90%E8%90%A5%E4%B8%93%E5%91%98.jpg'
                    ],
                    content:[
                        '负责各线上平台的日常运营及维护，执行线上活动的策划及推广。对行业新闻热点有敏感度，对新媒体运营有想法和创意。具备良好的逻辑思维能力、文案能力和策划能力，有丰富的想象力和敏锐的内容判断力。知名大学酒店管理、旅游管理、广告学、市场营销、传媒等专业本科或硕士毕业，国内985大学和全球酒店专业排名前10的大学毕业生优先。',
                    ]
                },
                {
                    title:'数据分析师（深圳）',
                    images: [
                        // 'https://oss.huamei2001.com/temp/decor/%E5%8A%A0%E5%85%A5-%E6%95%B0%E6%8D%AE%E5%88%86%E6%9E%90%E5%B7%A5%E7%A8%8B%E5%B8%88.jpg'
                    ],
                    content:[
                        '通过统计数据和分析，为公司运营决策、产品方向、运营策略提供数据支持。开发并持续完善公司各项业务数据的统计分析模型，确保其准确性、实用性及可衡量性。对经济环境、业务逻辑、数字敏感，具备良好的逻辑分析能力和数据可视化能力。熟练运用SPSS、Python、Tableau、Power BI等数据处理和分析工具。具备较强的英语阅读能力，能熟练使用ChatGPT等人工智能工具。知名大学酒店管理、旅游管理、计算机、MBA、财经、金融等专业本科或硕士毕业。国内985大学和全球酒店专业排名前10的大学毕业生、有咨询行业经验和酒店工作经验者优先。',
                    ]
                },
                {
                    title:'编程工程师（深圳）',
                    images: [
                        // 'https://oss.huamei2001.com/temp/decor/%E5%8A%A0%E5%85%A5-%E6%95%B0%E6%8D%AE%E5%88%86%E6%9E%90%E5%B7%A5%E7%A8%8B%E5%B8%88.jpg'
                    ],
                    content:[
                        '熟练掌握 Python、JavaScript、Go 中至少一种编程语言，并在实际项目中有应用经验。拥有微信小程序开发经验，熟悉网页应用开发，包括 HTML、CSS。了解 MySQL 等数据库接口语言，具备网站运营和维护经验。对软件开发充满兴趣，具有自主学习的能力和热情。能通过阅读技术文档，独立学习新的技术工具（如编程库、程序接口、SDK、网络API等）。具备较强的英语阅读能力，能熟练使用 ChatGPT 等人工智能工具。知名大学计算机、软件、数据分析、管理等专业本科或硕士毕业。优先考虑国内985大学和全球酒店专业排名前10的大学毕业生，有咨询行业经验者尤佳。',
                    ]
                },
                {
                    title:'实习生（深圳/北京/上海/成都）',
                    reverse:true,
                    images: [
                        // 'https://oss.huamei2001.com/temp/decor/%E5%8A%A0%E5%85%A5-%E5%AE%9E%E4%B9%A0%E7%94%9F.jpg'
                    ],
                    content:[
                        '协助顾问进行市场调研和信息收集。数据分析能力强，沟通能力强，有团队精神，能够连续实习6个月以上。国内985大学和全球酒店专业排名前10的大学生、有咨询行业经验和酒店工作经验者优先。',
                    ]
                },
            ]
        },
    ],
}